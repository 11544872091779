import React from 'react';
import { Image, Link, HStack, VStack, Stack, Text, Flex, SimpleGrid, View, useBreakpointValue } from 'native-base';
import selfkeyLogo from '../assets/images/logo-footer.svg';
import discordIcon from '../../selfkey-id/assets/icons/discord.svg';
import facebookIcon from '../../selfkey-id/assets/icons/facebook-icon.svg';
import linkedinIcon from '../../selfkey-id/assets/icons/linkedin-icon.svg';
import redditIcon from '../../selfkey-id/assets/icons/reddit-icon.svg';
import telegramIcon from '../../selfkey-id/assets/icons/telegram-icon.svg';
import twitterIcon from '../../selfkey-id/assets/icons/twitter-icon.svg';
import youtubeIcon from '../../selfkey-id/assets/icons/youtube-icon.svg';
import { FooterLink } from './footer_link';
import { JOIN_DISCORD_URL } from '../../selfkey-id/lib/constants';
import { useHistory } from 'react-router-dom';

export function Footer({ mvp = false }) {
  const history = useHistory();
  const today = new Date();
  const year = today.getFullYear();

  const flexColumns = useBreakpointValue({
    base: 1,
    lg: 6
  });
  const flexColumnsSpace = useBreakpointValue({
    base: '32px',
    lg: '48px'
  });
  const socialIconsSpace = useBreakpointValue({
    base: '24px',
    lg: '46px'
  });
  const footerTop = useBreakpointValue({
    base: '-85px',
    lg: '-45px'
  });
  const footerLogoMarginTop = useBreakpointValue({
    base: '-9px',
    lg: '31px'
  });
  return (
    <>
      <div
        style={{
          background: 'linear-gradient(90deg, rgba(3, 133, 228, 0) 0%, #0385E4 50%, rgba(3, 133, 228, 0) 100%)',
          height: '1px'
        }}
      ></div>
      <div
        style={{
          background: 'radial-gradient(59.7% 76.4% at 50% 0%, rgba(3, 133, 228, 0.35) 0%, black 50%)',
          height: '125px'
        }}
      ></div>

      <VStack bg={'#000000'} px={1} width="100%" mt="auto">
        <Flex width="100%" justifyContent="center" direction="row" space={0} alignItems="center" position={'absolute'} top={footerTop}>
          <Image
            source={{
              uri: selfkeyLogo
            }}
            alt="SelfKey Logo"
            w="133px"
            h="36px"
            resizeMode="contain"
          />
        </Flex>
        <Stack
          width={'100%'}
          maxWidth={'1024px'}
          mx={'auto'}
          mt={footerLogoMarginTop}
          space="40px"
          direction={{ base: 'column' }}
          justifyContent="space-between"
        >
          {/*<SimpleGrid minChildWidth={'100%'} columns={flexColumns} alignItems={'center'} mx="auto" space={flexColumnsSpace} flexWrap="wrap" mt={0}>
            <View width={'100%'} alignItems={'center'}>
              <FooterLink label="Identity Wallet" href="https://selfkey.org/download/" />
            </View>
            <View width={'100%'} alignItems={'center'}>
              <FooterLink label="Whitepaper" href="https://docsend.com/view/62bg3ygxbqdc873s" />
            </View>
            <View width={'100%'} alignItems={'center'}>
              <FooterLink label="About Us" href="https://selfkey.org/about-us/" />
            </View>
            <View width={'100%'} alignItems={'center'}>
              <FooterLink label="Team" href="https://selfkey.org/selfkey-team/" />
            </View>
            <View width={'100%'} alignItems={'center'}>
              <FooterLink label="Contact" href="https://selfkey.org/contact/" />
            </View>
            <View width={'100%'} alignItems={'center'}>
              <FooterLink label="Blog" href="https://selfkey.org/blog/" />
            </View>
          </SimpleGrid>*/}
          <HStack justifyContent={'center'} alignItems={'center'} space={socialIconsSpace}>
            {/*<Link href={JOIN_DISCORD_URL} isExternal _text={{ fontSize: 'xs' }} fontWeight="500">
              <Image src={discordIcon} alt="Alternate Text" w="24px" h="24px" />
            </Link>*/}
            <Link href="https://www.facebook.com/SelfKeyHQ/" isExternal _text={{ fontSize: 'xs' }} fontWeight="500">
              <Image src={facebookIcon} alt="Alternate Text" w="24px" h="24px" />
            </Link>
            <Link href="https://twitter.com/selfkey" isExternal _text={{ fontSize: 'xs' }} fontWeight="500">
              <Image src={twitterIcon} alt="Alternate Text" w="24px" h="24px" />
            </Link>
            <Link href="https://www.linkedin.com/company/selfkey/" isExternal _text={{ fontSize: 'xs' }} fontWeight="500">
              <Image src={linkedinIcon} alt="Alternate Text" w="24px" h="24px" />
            </Link>
            <Link href="https://www.youtube.com/channel/UCsilze3-MhbCY3_QkKul3PQ" isExternal _text={{ fontSize: 'xs' }} fontWeight="500">
              <Image src={youtubeIcon} alt="Alternate Text" w="24px" h="24px" />
            </Link>
            {/*<Link href="https://www.reddit.com/r/selfkey/" isExternal _text={{ fontSize: 'xs' }} fontWeight="500">
              <Image src={redditIcon} alt="Alternate Text" w="24px" h="24px" />
            </Link>*/}
            <Link href="https://t.me/selfkeyfoundation" isExternal _text={{ fontSize: 'xs' }} fontWeight="500">
              <Image src={telegramIcon} alt="Alternate Text" w="24px" h="24px" />
            </Link>
          </HStack>
          <VStack width={'100%'} m="auto" alignItems="center" justifyContent={'center'} space="2" mb={'80px'}>
            <Text fontSize="12px" fontWeight="400" lineHeight="16px" color="#B7B7B7" textAlign={'center'}>
              2017-{year} by SelfKey
            </Text>
            <Link onPress={() => history.push('/privacy-policy')} _text={{ fontSize: '12px', fontWeight: '400', lineHeight: '16px', color: '#FFFFFF' }}>
              Privacy Policy
            </Link>
            <Link onPress={() => history.push('/terms-of-service')} _text={{ fontSize: '12px', fontWeight: '400', lineHeight: '16px', color: '#FFFFFF' }}>
              Airdrop Agreement and Terms & Conditions
            </Link>
            <Link
              onPress={() => history.push('/selfkey-dao-governance-agreement')}
              _text={{ fontSize: '12px', fontWeight: '400', lineHeight: '16px', color: '#FFFFFF' }}
            >
              SelfKey DAO Governance Agreement
            </Link>
          </VStack>
        </Stack>
      </VStack>
    </>
  );
}
