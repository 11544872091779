import React, { useContext, useEffect, useState } from 'react';
import { Box, Flex, Heading, HStack, Image, Text, useBreakpointValue, VStack } from 'native-base';
import { ConnectWalletButton } from './connect_wallet_button';

import Logo from '../assets/icons/logo.svg';
import LogoMobile from '../assets/icons/logo-mobile.svg';
import LogoBlue from '../assets/icons/logo-blue.svg';
import LogoMobileBlue from '../assets/icons/logo-blue-mobile.svg';
import SelfTokenIcon from '../../lockdao/assets/icons/self-token.svg';
import DownArrowIcon from '../../lockdao/assets/icons/down-arrow-icon.svg';
import DownArrowLottie from '../assets/lotties/animated_arrow_down.json';
import { Link, useHistory } from 'react-router-dom';
import SkButton from '../../lockdao/pages/components/sk_button';
import { RewardsContext, RoutingContext, SelfTokenContext, VoteContext } from '../context/app_context';
import { checkStyle, convertNumber, notSupportedBrowser } from '../lib/helpers';
import { MechanicalCounter } from 'mechanical-counter';
import Lottie from 'lottie-react';
import WarningModal from '../components/warning_modal';
import discordIcon from '../assets/icons/discord.svg';
import { JOIN_DISCORD_URL } from '../lib/constants';
import { formatEther } from 'viem';

const shadowStyled = {
  MozBoxShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 6px 5px',
  WebkitBoxShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 6px 5px',
  boxShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 6px 5px'
};

export const HeaderBar = React.memo(({ mvp = true, skStyle = false, ...props }) => {
  const history = useHistory();
  const { rewards } = useContext(RewardsContext);
  const { userRoute, isRouting } = useContext(RoutingContext);
  const { activeProposal } = useContext(VoteContext);
  const { loadingGetSelfMintingUnlocked, isSelfMintingUnblocked } = useContext(SelfTokenContext);
  const [isMember, setIsMember] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const awardsEnabled = false;

  useEffect(() => {
    if (userRoute && !isRouting) {
      setIsMember(userRoute.startsWith('/member'));
    }
  }, [userRoute, isRouting]);
  const isSkStyle = checkStyle(skStyle);

  const lockdaoLogoProps = useBreakpointValue({
    base: {
      uri: LogoMobile
    },
    md: {
      uri: Logo
    }
  });
  const skLogoProps = useBreakpointValue({
    base: {
      uri: LogoMobileBlue
    },
    md: {
      uri: LogoBlue
    }
  });
  const maxWidth = useBreakpointValue({
    base: '100%',
    xl: '1216px'
  });
  const headerHeight = useBreakpointValue({
    base: '69px',
    md: '84px'
  });
  const rewardButtonSize = useBreakpointValue({
    base: '30px',
    md: '40px'
  });
  const animationSize = useBreakpointValue({
    base: '42px',
    md: '52px'
  });
  const animationPadding = useBreakpointValue({
    base: '1px 4px 1px 10px',
    md: '0 0 0 20px'
  });
  const animationRightOffset = useBreakpointValue({
    base: '-48px',
    md: '-58px'
  });

  const [onScrollStyle, setOnScrollStyle] = useState({ opacity: 0 });
  const [onScrollStyleBackdrop, setOnScrollStyleBackdrop] = useState('none');
  const listenScrollEvent = e => {
    const percentageScrolled =
      (document.documentElement.scrollTop + document.body.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
    let headerStyled = {
      opacity: percentageScrolled
    };
    if (percentageScrolled > 0.75) {
      headerStyled = {
        ...headerStyled,
        ...shadowStyled
      };
    } else if (percentageScrolled > 0) {
      setOnScrollStyleBackdrop('blur(5px)');
    } else if (percentageScrolled === 0) {
      setOnScrollStyleBackdrop('none');
    }
    setOnScrollStyle(headerStyled);
  };
  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
  }, [window.screenY]);
  const handleJoinDiscord = () => {
    window.open(JOIN_DISCORD_URL);
  };

  const handleSelfBalanceClick = async () => {
    if (isSelfMintingUnblocked) {
      history.push('/member/token-minting');
    } else {
      setShowWarningModal(true);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        height: headerHeight,
        width: '100%',
        position: 'fixed',
        top: '0',
        zIndex: '2',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: onScrollStyleBackdrop
      }}
    >
      <Box
        bg={'rgb(3, 12, 30)'}
        px={3}
        py={1}
        justifyContent="center"
        alignItems="center"
        position="fixed"
        top="0"
        w="100%"
        h={headerHeight}
        style={{ ...onScrollStyle }}
      />
      <HStack space={4} alignItems="center" width={'100%'} maxWidth={mvp ? maxWidth : '1024px'} justifyContent="space-between" px={{ base: '20px', xl: '0' }}>
        <Link to={'/'}>
          <Image
            alt="SelfKey Logo"
            source={isSkStyle ? skLogoProps : lockdaoLogoProps}
            w={{ base: isSkStyle ? '118px' : '28px', md: '164px' }}
            h={{ base: '32px', md: '44px' }}
          />
        </Link>
        <HStack alignItems={'center'}>
          {awardsEnabled && isMember && rewards?.total && !loadingGetSelfMintingUnlocked && (
            <SkButton
              eventName={'Header - Rewards'}
              variant={'secondary'}
              noPadding={true}
              padding={animationPadding}
              rightIcon={DownArrowIcon}
              rightIconSize={rewardButtonSize}
              w={'162px'}
              fixedSize={false}
              size={'small'}
              onPress={() => handleSelfBalanceClick()}
            >
              <Flex direction={'row'} alignItems={'center'}>
                <Image
                  source={{
                    uri: SelfTokenIcon
                  }}
                  w={'24px'}
                  h={'24px'}
                  alt="lock-icon"
                  resizeMode="contain"
                  mr={'10px'}
                />
                <Text fontWeight={700} fontSize={'14px'} lineHeight={'16px'}>
                  <MechanicalCounter text={convertNumber(formatEther(rewards.total))} height={'2em'} />
                </Text>
                <Lottie
                  animationData={DownArrowLottie}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    right: animationRightOffset,
                    width: animationSize,
                    height: animationSize
                  }}
                >
                  <Image
                    source={{
                      uri: DownArrowIcon
                    }}
                    alt="icon"
                    w={rewardButtonSize}
                    h={rewardButtonSize}
                    resizeMode="contain"
                    position={'absolute'}
                  />
                </Lottie>
              </Flex>
            </SkButton>
          )}
          {!notSupportedBrowser() && (
            <ConnectWalletButton type={'btn'} size="sm" disconnectBtn={true} skStyle={isSkStyle} mvp={mvp}>
              Connect Wallet
            </ConnectWalletButton>
          )}
        </HStack>
      </HStack>
      <WarningModal
        showModal={showWarningModal}
        title={
          <VStack>
            <Heading fontSize={{ base: '20px', md: '28px' }} lineHeight={{ base: '24px', md: '36px' }} fontWeight={700} textAlign={'center'}>
              SELF minting is not yet available, pending DAO vote.
            </Heading>
          </VStack>
        }
        actionButton={
          activeProposal?.active && (
            <SkButton
              fixedSize={false}
              alignSelf={'center'}
              mt={{ base: 0, md: '8px' }}
              mb={{ base: 0, md: '24px' }}
              w={'185px'}
              onPress={() => {
                setShowWarningModal(false);
                history.push('/member/vote');
              }}
            >
              Vote Now
            </SkButton>
          )
        }
        leftButton={
          <SkButton
            eventName={'Join Discord - Modal SELF Minting not available'}
            variant={'secondary'}
            leftIcon={discordIcon}
            leftIconSize={'16px'}
            fixedSize={false}
            onPress={() => handleJoinDiscord()}
          >
            Join Discord
          </SkButton>
        }
        rightButton={
          <SkButton
            eventName={'OK - Modal SELF Minting not available'}
            fixedSize={false}
            variant={'secondary'}
            onPress={() => setShowWarningModal(false)}
            w={'185px'}
          >
            Close
          </SkButton>
        }
        handleClose={() => setShowWarningModal(false)}
      />
    </div>
  );
});
