import React, { useEffect } from 'react';
import { Box, Heading, HStack, Image, Link, Spinner, Stack, Text, useBreakpointValue, View, VStack } from 'native-base';
import { PagesBackground } from '../../common/layout/main_layout.styled';
import { ModelLoader } from '../../common/components/model_loader';
import { JoinDiscordCard } from '../../common/components/join_discord_card';
import { SkButton } from '../../common/components/sk_button';
import { useHistory } from 'react-router-dom';
import { TransactionFailed } from './components/transaction_failed';
import { TransactionCompleted } from './components/staking_completed';
import { RejectedPage } from './rejected_page';
import TermsAndConditionsCheckbox from './components/terms_checkbox';
import SkFrame from './components/sk_frame';
import { SkBackButton } from './components/sk_back_button';
import { SkWaitingTransaction } from './components/sk_waiting_transaction';
import keyToken from '../../common/assets/icons/key-token.svg';
import selfToken from '../../common/assets/icons/self-token.svg';
import useDocumentTitle from '../../common/hooks/use_document_title';
import { APP_NAME, PAGES } from '../../common/lib/constants';
import LdButton from './components/sk_button';
import WarningModal from '../../common/components/warning_modal';
import { SelfieModal } from '../../common/components/selfie_modal';
import { POICheckFailed } from '../../common/components/poi_check_failed';
import { BridgeButton } from '../../common/components/brigde_button';
import useStakingPage from '../../common/hooks/use_staking_page';
import { SigningConsentPage } from '../../common/components/signing-content-page';
import { HoverTooltip } from '../../common/components/hover-tooltip';
import { SkNumericInput } from './components/sk_input';

function StakingPageContainer() {
  const {
    minStakeAmount,
    isLoadingMinStakeAmount,
    keyBalance,
    isRefetchingKeyBalance,
    keyToStake,
    setKeyToStake,
    isMaxAmountPending,
    setIsMaxAmountPending,
    minAmountWarning,
    setMinAmountWarning,
    maxAmountWarning,
    setMaxAmountWarning,
    acceptedTerms,
    displayAcceptTermsWarning,
    showTakeSelfieModal,
    setShowTakeSelfieModal,
    showConsentModal,
    setShowConsentModal,
    consentView,
    loadingSignConsent,
    errorMessage,
    error,
    eligibleError,
    poiCheckFailedError,
    formattedStakingTotal,
    loadingSkr,
    loadingVka,
    isLoadingStaking,
    isWaitingAllowanceApproval,
    isWaitingStakingApproval,
    stakingTransaction,
    hasError,
    loadingStakingTotal,
    stakingTotal,
    acceptTerms,
    setMaxAmount,
    validateMinStakeAmount,
    handleStakeNow,
    handleStakingCompleteContinue,
    handleDownloadCredential,
    handleNotEligibleOk,
    handlePOIContinue,
    handleAgreeConsent
  } = useStakingPage();

  const { setDocumentTitle } = useDocumentTitle();
  const history = useHistory();

  const agreeTermsStyle = useBreakpointValue({
    base: {
      paddingLeft: '2px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      color: 'white'
    },
    md: {
      paddingLeft: '2px',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      color: 'white'
    }
  });

  useEffect(() => {
    if (hasError || error) {
      setDocumentTitle(`${APP_NAME} - ${PAGES.LOCK_POI} - Error`);
    } else if (stakingTransaction) {
      setDocumentTitle(`${APP_NAME} - ${PAGES.LOCK_POI} - Success`);
    } else {
      setDocumentTitle(`${APP_NAME} - ${PAGES.LOCK_POI}`);
    }
  }, [hasError, stakingTransaction, error, setDocumentTitle]);

  useEffect(() => {
    if (isMaxAmountPending && !isRefetchingKeyBalance && keyBalance) {
      setIsMaxAmountPending(false);
      setKeyToStake(keyBalance);
      setMinAmountWarning(false);
      setMaxAmountWarning(false);
    }
  }, [isMaxAmountPending, isRefetchingKeyBalance, keyBalance]);

  if (eligibleError) {
    setDocumentTitle(`${APP_NAME} - ${PAGES.LOCK_POI} - Not Eligible`);
    return (
      <RejectedPage
        title={'Oh, no!'}
        content={'You are not eligible for this action.'}
        actionLabel={'OK'}
        handleAction={handleNotEligibleOk}
        skStyle={false}
        eventPage={`${PAGES.LOCK_POI} - Not Eligible`}
      />
    );
  }

  if (error) {
    return (
      <RejectedPage
        includeLayout={false}
        title={'Oh, no!'}
        content={'Something went wrong, please try again'}
        actionLabel={'Retry'}
        handleAction={handleStakingCompleteContinue}
        eventPage={`${PAGES.LOCK_POI} - ${errorMessage}`}
      />
    );
  }

  if (hasError) {
    return <TransactionFailed title={`${PAGES.LOCK_POI} failed`} onRetry={handleStakingCompleteContinue} skStyle={false} />;
  }

  if (stakingTransaction) {
    return <TransactionCompleted tx={stakingTransaction} handleAction={handleStakingCompleteContinue} skStyle={false} />;
  }

  if (isWaitingAllowanceApproval || isWaitingStakingApproval) {
    return <SkWaitingTransaction isWaitingForTransaction={!isWaitingAllowanceApproval} skStyle={false} />;
  }

  if (poiCheckFailedError) {
    return <POICheckFailed handleAction={handleStakingCompleteContinue} page={PAGES.LOCK_POI} />;
  }

  if (loadingSignConsent) {
    return <SigningConsentPage />;
  }

  return (
    <PagesBackground>
      <Box flexDirection="column" alignItems={{ base: 'center', lg: 'stretch' }} maxWidth={'1216px'} width={'100%'}>
        <VStack space={{ base: '40px', md: '64px' }}>
          <Stack direction={{ base: 'column', lg: 'row' }} space={'32px'}>
            <SkBackButton eventName={PAGES.LOCK_POI} onPress={() => history.push('/member')} />
            <Stack alignItems="center" textAlign="center" alignSelf={'center'} display={{ base: 'block', lg: 'none' }}>
              <View w={'310px'} h={'310px'}>
                <ModelLoader model={'/3d/step-2/lock_credential.glb'} scale={0.5}></ModelLoader>
              </View>
            </Stack>
            <SkFrame h={{ base: '730px', md: '844px' }} mr={{ base: 0, md: '46px' }} px={{ base: 0, md: '40px' }}>
              <VStack alignItems={'center'} justifyContent={'center'} h={'100%'} space={'24px'}>
                <VStack alignItems={'center'} space={{ base: '12px', md: '8px' }}>
                  <Text fontWeight={400} fontSize={{ base: '16px', md: '20px' }} lineHeight={{ base: '24px', md: '32px' }} color={'#FFEDD2'}>
                    KEY locking your POI Credential:
                  </Text>
                  <HStack alignItems={'center'}>
                    {loadingStakingTotal ? (
                      <Spinner color={'indigo.500'} />
                    ) : (
                      <Heading
                        fontFamily={'Plus Jakarta Sans'}
                        fontWeight={700}
                        fontSize={{ base: '18px', md: '32px' }}
                        lineHeight={{ base: '22px', md: '40px' }}
                      >
                        <HoverTooltip content={`${stakingTotal} $KEY`} disabled={!formattedStakingTotal.wasRounded}>
                          <Text>
                            {formattedStakingTotal.formattedValue}
                            {formattedStakingTotal.wasRounded ? <Text fontSize={'12px'}>...</Text> : ''} $KEY
                          </Text>
                        </HoverTooltip>
                      </Heading>
                    )}
                    <Image alt="key token" source={{ uri: keyToken }} w={{ base: '18px', md: '33px' }} h={{ base: '18px', md: '32px' }} ml={'10px'} />
                  </HStack>
                </VStack>
                <VStack space={{ base: '12px', md: '16px' }} alignItems={'center'}>
                  {/*{lockTimeDuration === 0 ? (*/}
                  <Text
                    fontWeight={400}
                    fontSize={{ base: '14px', md: '16px' }}
                    lineHeight={{ base: '20px', md: '24px' }}
                    pb={'4px'}
                    alignItems={'center'}
                    maxW={{ base: '264px', md: '302px' }}
                    textAlign={'center'}
                  >
                    Lock your POI credential with KEY <Image alt="key token" source={{ uri: keyToken }} w={'16px'} h={'16px'} /> and mint SELF{' '}
                    <Image alt="self token" source={{ uri: selfToken }} w={'16px'} h={'16px'} />
                  </Text>
                  {/*) : (*/}
                  {/*  <Text fontWeight={400} fontSize={{ base: '14px', md: '16px' }} lineHeight={{ base: '20px', md: '24px' }} pb={'4px'}>*/}
                  {/*    {`Lock your $KEY For ${msToTime(lockTimeDuration)} and earn $LOCK!`}*/}
                  {/*  </Text>*/}
                  {/*)}*/}
                  <VStack alignItems={'center'} space={{ base: '12px', md: '16px' }} display={'none'}>
                    <SkNumericInput
                      onChange={value => {
                        setMinAmountWarning(!validateMinStakeAmount(value));
                        setMaxAmountWarning(parseFloat(value) > parseFloat(keyBalance));
                        setKeyToStake(value);
                      }}
                      value={keyToStake}
                      rightElement={
                        <SkButton
                          eventName={`${PAGES.LOCK_POI} - Max`}
                          w={{ base: '70px', md: '100px' }}
                          h={{ base: '40px', md: '48px' }}
                          fixedSize={false}
                          pr={'12px'}
                          isLoading={isRefetchingKeyBalance}
                          onPress={() => setMaxAmount()}
                        >
                          Max
                        </SkButton>
                      }
                    />
                    {isLoadingMinStakeAmount && <Spinner color={'warning.500'} />}
                    {!isLoadingMinStakeAmount && !maxAmountWarning && (
                      <Text
                        fontWeight={400}
                        fontSize={{ base: '10px', md: '12px' }}
                        lineHeight={{ base: '14px', md: '16px' }}
                        color={minAmountWarning ? 'warning.500' : '#FFEDD2'}
                        display={'flex'}
                        alignItems={'center'}
                      >
                        {`Minimum amount is ${minStakeAmount} KEY`}
                        <Image alt="key token" source={{ uri: keyToken }} w={'16px'} h={'16px'} ml={'2px'} />
                      </Text>
                    )}
                    {!isLoadingMinStakeAmount && maxAmountWarning && (
                      <Text
                        fontWeight={400}
                        fontSize={{ base: '10px', md: '12px' }}
                        lineHeight={{ base: '14px', md: '16px' }}
                        color={'warning.500'}
                        display={'flex'}
                        alignItems={'center'}
                      >
                        You don't have enough KEY <Image alt="key token" source={{ uri: keyToken }} w={'16px'} h={'16px'} /> in your wallet
                      </Text>
                    )}
                  </VStack>
                  <SkButton
                    eventName={'LOCK POI - Lock Now'}
                    w={{ base: '137px', md: '280px' }}
                    fixedSize={false}
                    pt={'12px'}
                    onPress={() => handleStakeNow()}
                    isLoading={isLoadingStaking || loadingSkr || loadingVka || loadingSignConsent}
                  >
                    Lock Now
                  </SkButton>
                </VStack>
                <VStack alignItems={'center'} space={{ base: '4px', md: '8px' }} mb={{ base: 0, md: '15px' }}>
                  <TermsAndConditionsCheckbox isChecked={acceptedTerms} onChange={e => acceptTerms(e)} />
                  {displayAcceptTermsWarning && (
                    <Heading
                      fontWeight={400}
                      fontSize={{ base: '10px', md: '12px' }}
                      lineHeight={{ base: '14px', md: '16px' }}
                      color={'#FF7575'}
                      textAlign={{ base: 'center', md: 'left' }}
                    >
                      Please agree to the Terms And Conditions before continuing.
                    </Heading>
                  )}
                </VStack>
                <HStack alignItems={'center'} mb={{ base: '8px', md: '15px' }}>
                  <Link onPress={() => history.push('/member/withdraw')} _text={agreeTermsStyle} _web={agreeTermsStyle} pt={{ base: '8px', md: 0 }}>
                    Unlock KEY
                  </Link>
                  <Image alt="key token" source={{ uri: keyToken }} w={'16px'} h={'16px'} ml={'2px'} />
                </HStack>
                <BridgeButton page={PAGES.LOCK_POI} mb={{ base: '8px', md: '15px' }} maxW={{ base: '320px', md: '100%' }} alignItems={'center'} />
                <SkButton
                  eventName={'Download Credential'}
                  w={{ base: '174px', md: '226px' }}
                  fixedSize={false}
                  onPress={handleDownloadCredential}
                  variant={'secondary'}
                >
                  Download Credential
                </SkButton>
              </VStack>
            </SkFrame>

            <Stack alignItems="center" textAlign="center" space="4" display={{ base: 'none', lg: 'block' }} minW={'420px'} minH={'420px'}>
              <View w={'515px'} h={'515px'}>
                <ModelLoader model={'/3d/step-2/lock_credential.glb'} scale={0.5}></ModelLoader>
              </View>
            </Stack>
          </Stack>
        </VStack>
        <SkBackButton eventName={PAGES.LOCK_POI} onPress={() => history.push('/member')} isMobile={true} my={'40px'} />
        <JoinDiscordCard my={{ base: '60px', md: '160px' }} alignSelf={'center'} skStyle={false} />
      </Box>
      <WarningModal
        showModal={showConsentModal}
        title={consentView}
        leftButton={
          <LdButton variant={'secondary'} fixedSize={false} w={'134px'} onPress={() => setShowConsentModal(false)}>
            Cancel
          </LdButton>
        }
        rightButton={
          <LdButton fixedSize={false} w={'134px'} onPress={() => handleAgreeConsent()}>
            Agree
          </LdButton>
        }
        handleClose={() => setShowConsentModal(false)}
      />
      <SelfieModal isOpen={showTakeSelfieModal} handleClose={() => setShowTakeSelfieModal(false)} handlePOIContinue={handlePOIContinue} />
    </PagesBackground>
  );
}

export { StakingPageContainer };
